import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import PageImageHeader from "../components/common/PageImageHeader"
import SectionTitle from "../components/common/SectionTitle"
import SEO from "../components/seo"
import HeaderImage from "../images/about-page-header-image.jpg"
import HomeNews from "../components/HomeNews"

const StyledAboutPage = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
`

const AboutPage = () => {
  return (
    <>
      <SEO
        title="about"
        description="Listen live to the best in Nerdcore and Geek Rock music."
      />
      <Layout>
        <PageImageHeader imageHeader={HeaderImage} />
        <SectionTitle title="About Us" />
        <StyledAboutPage>
          <h1>Welcome to Geek Life Radio</h1>
          <p>
            We are a 24/7 “Geek” Live Radio stream. We bring you the best in
            Geek audio from around the world! Geek Life Radio stream consists of
            independent nerdcore and geeks rock music and two talk shows daily.
          </p>
          <p>
            To go along with our live radio stream we also have a growing
            Podcast directory focused on Geek culture content. Current desired
            content includes, Comics,, Video Games, Pro Wrestling, Table Top
            Games, Movies and TV, and Technology.
          </p>
          <p>
            We are currently in a rebuilding and re-branding of our website and
            station (excuse the mess and broken links). Future features on the
            horizon include live DJs and live (not pre-recorded) talk shows.
          </p>
          <p>
            Geek Life Radio always welcomes new ideas for our website and
            station. Feel free to reach out if you have anything you would like
            to say.{" "}
          </p>
          <p>
            We are currently accepting submissions for both podcasts and music.
            Our Schedule starts at 6a.m. and runs until 1a.m. Monday through
            Saturday. All times are Central.
          </p>
          <p>Enjoy the new Geek Life Radio!</p>
        </StyledAboutPage>
      </Layout>
    </>
  )
}

export default AboutPage
